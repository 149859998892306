import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import scrollTo from 'gatsby-plugin-smoothscroll'
import Hero from "../components/hero"
import ArrowRight from "../components/svg/arrowRight"
import Seo from "../components/seo"
import {StaticImage} from "gatsby-plugin-image"
import Cta from "../components/cta"

const FeezyPage = () => (
    <Layout>
        <Seo 
            title="Feezy 2-sided marketplace startup case study" 
            description="Case study of the Feezy Marketplace startup website and application development work completed by Launch Lab."
        />

        <Hero
            h1="2-sided marketplace development case study"  
            h2="Feezy"
        />

        
        <section className="bg-noise feezy section-pad-sm">
            <div className="container" id="anchor">
                <div className="row">
                    <StaticImage 
                        src="../images/feezy-main.png"
                        alt="Feezy logo"
                        formats={["AUTO", "WEBP", "AVIF"]}
                        className="porti-top-img"
                        placeholder="none"
                        backgroundcolor="white"
                        layout="fullWidth"
                        quality="100%"
                    />
                </div>
            </div>

        </section>
        
        <section className="section-pad-sm">
            <div className="container">
                <div className="row">
                    <div className="porti-wrapper">
                        <div className="cols">
                            <div className="col-26">
                                <div className="sticky-porti-menu">
                                    <button onClick={() => scrollTo('#bg')} className="btn">
                                        Background
                                    </button>
                                    <button onClick={() => scrollTo('#services')} className="btn">
                                        Services
                                    </button>
                                    <button onClick={() => scrollTo('#work')} className="btn">
                                        The work
                                    </button>
                                    <button onClick={() => scrollTo('#project')} className="btn">
                                        View project
                                    </button>
                                </div>
                            </div>
                            <div className="col-67 long-text">
                                <div id="bg" className="porti-text-section">
                                    <h3>Background</h3>
                                    <p>Feezy is a 2-sided marketplace startup that enables learning institutions, like universities, to list seats that Agents can secure.</p>
                                    <p><strong>[ More information on this startup development project coming soon ]</strong>.</p>
                                </div>
                                <div id="work" className="porti-text-section">
                                    <h3>The work</h3>
                                    <p>Launch Lab worked with the founder from initial wireframes, product design and development of the web application.</p>
                                </div>
                                <div id="services" className="porti-text-section">
                                    <h4>Services</h4>
                                    <Link to="/startup-web-designers/" className="btn btn-lg btn-line">Startup web design</Link>
                                    <Link to="/gatsby-developer/" className="btn btn-lg btn-line">Gatsby website</Link>
                                    <Link to="/python-developer/" className="btn btn-lg btn-line">Python</Link>
                                    <Link to="/react-developers-sydney-canberra/" className="btn btn-lg btn-line">React</Link>
                                    <Link to="/startup-web-application-development/" className="btn btn-lg btn-line">Startup development</Link>
                                    <Link to="/aws-infrastructure/" className="btn btn-lg btn-line">AWS</Link>
                                </div>
                                <div id="project" className="porti-text-section">
                                    <h5>View project</h5>
                                    <p>The Feezy website is publicly accessible. However, access to the marketplace is invite only.</p>
                                    <a href="https://feezy.io/" className="btn btn-lg btn-purple">View Feezy website</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="bg-purple with-pad">
            <div className="container-lg">
                <div className="row">
                <div className="work-jumbo-wrapper with-radius">
                        <StaticImage 
                            src="../images/launch-lab-work-2024.png"
                            alt="Screengrabs of some of the websites and applications that Launch Lab have designed and developed"
                            formats={["AUTO", "WEBP", "AVIF"]}
                            placeholder="none"
                            backgroundcolor="white"
                            layout="fullWidth"
                            quality="100%"
                            className="with-radius"
                        />
                </div>
                <div className="flex flex-jc">
                    <Link to="/portfolio/" className="btn btn-lg btn-pink">
                    <span className="flex flex-ac">
                        View all projects
                        <ArrowRight />
                    </span>
                    </Link>
                </div>
                </div>
            </div>
        </section>
        
        <Cta 
            text="Get an obligation free web design or web development quote from an experienced local Australian development team."
        />
    </Layout>
)

export default FeezyPage